import React from "react";
import loadable from "@loadable/component";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QUICK_BOOK_ROUTE, VERIFICATION_ROUTE } from "./constants";
import Verification from "./components/public_pages/verification";
const QuickBook = loadable(() =>
  import("./components/public_pages/quick_book")
);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">loading</div>
  </div>
);

function App() {
  return (
    <div>
      <Router>
        <React.Suspense fallback={loading}>
          <Routes>
            <Route exact path={QUICK_BOOK_ROUTE} element={<QuickBook />} />
            <Route
              exact
              path={`${VERIFICATION_ROUTE}/:vidnikValue/:sessionValue`}
              element={<Verification />}
            />
          </Routes>
        </React.Suspense>
      </Router>
    </div>
  );
}
export default App;
