import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Success from "./success";
import FAILED from "./failed";
const page_style = {
  color: "white",
  padding: "25px",
  fontSize: "30px",
  textAlign: "justify",
};
const Verification = () => {
  const location = useLocation();
  const [result, set_result] = useState({ status_code: 0, message: "" });
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const [, , , , user_id, otp] = pathSegments;
    // Perform any necessary actions with the extracted values
    axios
      .get(
        `${
          location.pathname.split("web_vidnik")[0]
        }auth/verify_link/${user_id}/${otp}`
      )
      .then((response) => response.data)
      .then((res) => set_result(res))
      .catch((err) => set_result(err.toString()));
  }, [location]);
  return (
    <div style={page_style}>
      {result.status_code === 0 ? (
        "Please wait"
      ) : result.status_code === 200 ? (
        <Success message={result.message} />
      ) : (
        <FAILED message={result.message} />
      )}
    </div>
  );
};
export default Verification;
